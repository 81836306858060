'use client'

import Link from 'next/link'
import Image from 'next/image'
import { Menu, MenuButton, MenuItems, MenuItem, Transition } from '@headlessui/react'
import { signOut } from 'next-auth/react'

interface DropdownProfileProps {
  align?: 'left' | 'right';
  picture: string;
  givenName: string;
  familyName: string;
  role: string;
}

export default function DropdownProfile({ align, picture, givenName, familyName, role }: DropdownProfileProps) {
  const fullName = `${givenName} ${familyName}`.trim();
  const handleSignOut = async (e: React.MouseEvent) => {
    e.preventDefault()
    await signOut({ callbackUrl: '/' })
  }

  const dashboardLink = `/dashboard/${role?.toLowerCase() ?? ''}`;

  return (
    <Menu as="div" className="relative inline-flex">
      <MenuButton className="mb-3 inline-flex justify-center items-center group border-2 border-primary rounded-full p-1">
        <Image className="w-6 h-6 rounded-full object-cover" src={picture || "/images/logo.png"} width={32} height={32} alt="User" />

      </MenuButton>
      <Transition
        as="div"
        className={`origin-top-right z-10 absolute top-full min-w-[11rem] bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700/60 py-1.5 rounded-lg shadow-lg overflow-hidden mt-1 ${align === 'right' ? 'right-0' : 'left-0'}`}
        enter="transition ease-out duration-200 transform"
        enterFrom="opacity-0 -translate-y-2"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200 dark:border-gray-700/60">
          <div className="font-medium text-gray-800 dark:text-gray-100">{fullName || "User"}</div>
        </div>
        <MenuItems as="ul" className="focus:outline-none">
          <MenuItem as="li">
            {({ active }) => (
              <Link href={dashboardLink} className={`font-medium text-sm flex items-center py-1 px-3 ${active ? 'text-gray-900 dark:text-gray-200' : 'text-gray-400'}`}>
                Dashboard
              </Link>
            )}
          </MenuItem>
          <MenuItem as="li">
            {({ active }) => (
              <button
                onClick={handleSignOut}
                className={`font-medium text-sm flex items-center py-1 px-3 w-full text-left ${active ? 'text-gray-900 dark:text-gray-200' : 'text-gray-400'}`}
              >
                Abmelden
              </button>
            )}
          </MenuItem>
        </MenuItems>
      </Transition>
    </Menu>
  )
}