import Link from "next/link";

interface LogoProps {
  size?: number;
  className?: string;
}

/**
 * Logo component that renders a scalable SVG logo with a link to the homepage
 * @param size - Desired height of the logo in pixels (Default: 30)
 * @param className - Additional CSS classes for the Link wrapper
 */
export default function Logo({ size = 30, className = "" }: LogoProps) {
  // Original dimensions from the SVG file
  const originalWidth = 207;
  const originalHeight = 64;

  // Calculate scaling while maintaining aspect ratio
  const scale = size / originalHeight;
  const scaledWidth = Math.round(originalWidth * scale);
  const scaledHeight = size;

  return (
    <Link href="/" className={`inline-flex ${className}`} aria-label="Home">
      <img
        src="/images/Guideful_Logo_Full.svg"
        width={scaledWidth}
        height={scaledHeight}
        alt="Guideful Logo"
        style={{ minWidth: `${scaledWidth}px` }}
      />
    </Link>
  );
}
