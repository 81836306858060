// components/ui/Button.tsx
import React from 'react';
import Link from 'next/link';

interface NavButtonProps {
  href: string;
  children: React.ReactNode;
  className?: string;
}

export const NavButton: React.FC<NavButtonProps> = ({
                                                      href,
                                                      children,
                                                      className = '',
                                                    }) => {
  return (
    <Link
      href={href}
      className={`rounded-full text-primary hover:text-primary-dark border-2 border-primary hover:border-primary-dark flex items-center justify-center px-3 pt-1 h-8 text-sm font-bold uppercase transition-colors duration-300 bg-white ${className}`}
    >
      {children}
    </Link>
  );
};