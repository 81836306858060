'use client';

import React, { useEffect } from 'react';

interface Props {
  children: React.ReactNode;
}

function isTwilioError(error: any): boolean {
  if (!error) return false;
  const message = error.message?.toLowerCase() || '';
  return (
    message.includes('twilsock') ||
    message.includes('twilio') ||
    message.includes('transport') ||
    message.includes('connection is not initialized')
  );
}

const ErrorHandler: React.FC<Props> = ({ children }) => {
  useEffect(() => {
    const handleUnhandledRejection = (event: PromiseRejectionEvent) => {
      if (isTwilioError(event.reason)) {
        event.preventDefault();
        console.warn('Suppressed Twilio error:', event.reason.message);
      }
    };

    const handleError = (event: ErrorEvent) => {
      if (isTwilioError(event.error)) {
        event.preventDefault();
        console.warn('Suppressed Twilio error:', event.error.message);
      }
    };

    window.addEventListener('unhandledrejection', handleUnhandledRejection);
    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
      window.removeEventListener('error', handleError);
    };
  }, []);

  return children;
};

class TwilioErrorBoundary extends React.Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // For Twilio errors, don't change state - just suppress them
    if (isTwilioError(error)) {
      console.warn('Suppressed Twilio error in boundary:', error.message);
      return null; // This means no UI change will happen
    }
    // Only set error state for non-Twilio errors
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (!isTwilioError(error)) {
      console.error('Non-Twilio error caught:', error, errorInfo);
    }
  }

  render() {
    // This UI only shows for non-Twilio errors
    if (this.state.hasError) {
      // You could also return null here if you don't want any error UI
      return null;
    }

    // Normal case - wrap children in our error handler
    return <ErrorHandler>{this.props.children}</ErrorHandler>;
  }
}

export default TwilioErrorBoundary;