'use client';

import React, { useEffect, useState } from "react";
import Logo from "../logo-full";
import {NavButton} from '@/components/ui/button';
import { useSession } from "next-auth/react";
import { useRouter } from 'next/navigation';
import useSWR from 'swr';
import { Session } from "next-auth";
import { signOut } from 'next-auth/react'
import SearchModal from "@/components/ui/search-modal";
import {Grid, Search} from "flowbite-react-icons/outline";
import Image from 'next/image'
import DropdownProfile from "@/components/ui/dashboard/dropdown-profile";

interface UserData {
  id: string;
  picture?: string;
  given_name?: string;
  family_name?: string;
  role?: string;
}

const useUserData = (session: Session | null) => {
  const fetcher = (url: string): Promise<UserData> => fetch(url).then((res) => res.json());
  const userId = session?.user?.id;
  const userRole = session?.user?.role;

  const { data, error, mutate } = useSWR<UserData>(
    userId ? `/api/${userRole?.toLowerCase()}/${userId}` : null,
    fetcher
  );

  return {
    userData: data,
    isLoading: !error && !data,
    isError: error,
    mutate
  };
};

export default function Header() {
  const [top, setTop] = useState<boolean>(true);
  const { data: session } = useSession();
  const router = useRouter();
  const { userData, isLoading, isError, mutate: mutateUserData } = useUserData(session);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleSignOut = async (e: React.MouseEvent) => {
    e.preventDefault()
    await signOut({ callbackUrl: '/' })
  }

  // detect whether user has scrolled the page down by 10px
  const scrollHandler = () => {
    window.pageYOffset > 10 ? setTop(false) : setTop(true);
  };

  useEffect(() => {
    if(session?.user && !session.user.role) {
      handleIncompleteSession()
    }

    scrollHandler();
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top, session?.user]);

  const handleIncompleteSession = async () => {
    await signOut({ redirect: false });
  };

  return (
    <header className="fixed z-30 w-full pt-8 bg-white" style={{paddingRight: 'var(--scrollbar-width, 0px)'}}>
      <div className="mx-auto px-4 sm:px-6">
        <div className="relative flex h-14 items-center justify-between gap-3 bg-white px-3">
          {/* Site branding */}
          <div className="flex flex-1 items-center mb-2 -ml-3">
            <Logo size={40}/>
          </div>

          {/* Desktop sign in links */}
          <ul className="flex items-center justify-end gap-3">
            {/* Search button */}
            <li className="relative">
              <div>
                <button
                  className={`w-9 h-9 flex items-center justify-center hover:bg-gray-100 lg:hover:bg-gray-200 dark:hover:bg-gray-700/50 dark:lg:hover:bg-gray-800 rounded-full ml-3 ${searchModalOpen && 'bg-gray-200 dark:bg-gray-800'}`}
                  onClick={() => {
                    setSearchModalOpen(true)
                  }}
                >
                  <span className="sr-only">Suche</span>
                  <Search className="text-primary-500/80 dark:text-primary-400/80"/>
                </button>
                <SearchModal isOpen={searchModalOpen} setIsOpen={setSearchModalOpen}/>
              </div>
            </li>
            {session ? (
              <>
                <li className="relative md:block">
                  <button
                    onClick={() => router.push('/dashboard/customer')}
                    className="rounded-full text-primary hover:text-primary-dark border-2 border-primary hover:border-primary-dark inline-flex items-center justify-center px-3 h-8 pt-1 text-sm font-bold uppercase transition-colors duration-300 bg-white leading-none md:block hidden"
                  >
                    Dashboard
                  </button>
                  {/* Mobile dashboard icon */}
                  <button
                    onClick={() => router.push('/dashboard/customer')}
                    className="md:hidden rounded-full border-2 border-primary w-8 h-8 flex items-center justify-center"
                  >
                    <Grid className="w-5 h-5 text-primary"/>
                  </button>
                </li>
                <li>
                  {/* Mobile View */}
                  <div className="md:hidden mt-3">
                    {userData && (
                      <DropdownProfile
                        align="right"
                        picture={userData.picture || session?.user?.picture || "/images/logo.png"}
                        givenName={userData.given_name as string}
                        familyName={userData.family_name as string}
                        role={userData.role as string}
                      />
                    )}
                  </div>

                  {/* Desktop View */}
                  <button
                    onClick={handleSignOut}
                    className="hidden md:inline-flex rounded-full text-primary hover:text-primary-dark border-2
        border-primary hover:border-primary-dark items-center justify-center
        px-3 h-8 text-sm font-bold uppercase transition-colors duration-300 bg-white leading-none gap-2"
                  >
                    <Image
                      className="w-5 h-5 rounded-full object-cover"
                      src={userData?.picture || session?.user?.picture || "/images/logo.png"}
                      width={32}
                      height={32}
                      alt="User"
                    />
                    <span className="mt-1">Abmelden</span>
                  </button>
                </li>
              </>
            ) : (
              <>
                <li className="relative">
                  <NavButton href="/auth/signin">
                    Login
                  </NavButton>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </header>
  );
}